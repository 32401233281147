import React from 'react';
import { Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap
import Skeleton from 'react-loading-skeleton'; // Import Skeleton component
import 'react-loading-skeleton/dist/skeleton.css';


// Skeleton Placeholder for Company Card
const CompanyCardSkeleton = () => {
    return (
        <div className="imagecard" style={{ position: 'relative', height: 250, marginBottom: 10 }}>
            <Skeleton style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
            <Row className="content row-card">
                <Col>
                    <h1>
                        <Skeleton width={150} />
                    </h1>
                    <h2>
                        <Skeleton width={100} />
                    </h2>
                </Col>
                <Col md={3} xl={2} xs={2} lg={3} className="arrow-icon">
                    <Skeleton circle={true} height={30} width={30} />
                </Col>
            </Row>
        </div>
    );
};

const CompanyCard = React.memo(({ company, nextClicked }) => {
    return (
        <div
            onClick={() => nextClicked(company)}
            className="imagecard"
            key={company.id}
        >
            <img
                alt={`${company.company_name}_image`}
                loading="lazy"
                src={
                    company.companybusinesspage != null &&
                        company.companybusinesspage.hero_image
                        ? company.companybusinesspage.hero_image
                        : `${process.env.PUBLIC_URL + "/placeholder.webp"}`
                }
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: 10,
                    objectFit: 'cover',
                }}
            />
            <Row className="content row-card">
                <Col>
                    <h1
                        style={{
                            fontSize: 20,
                            fontWeight: 400,
                            color: 'white',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {company.company_name}
                    </h1>
                    <h2
                        style={{
                            fontSize: 18,
                            fontWeight: 400,
                            color: 'white',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {`${company.city}, ${company.country}`}
                    </h2>
                </Col>
                <Col md={3} xl={2} xs={2} lg={3} className="arrow-icon">
                    <img src={require('../../assets/right-arrow.png')} style={{ height: 30, width: 30 }} />
                </Col>
            </Row>
        </div>
    );
});

export { CompanyCard, CompanyCardSkeleton };
