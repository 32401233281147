import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const InstaGridSkeleton = ({ count = 6 }) => {
    return (
        <div className="gridStyle">
            {Array(count).fill().map((_, index) => (
                <div key={index} className="itemContainer">
                    <Skeleton width="100%" height={200} style={{ borderRadius: 10 }} />
                </div>
            ))}
        </div>
    );
};

const InstaGrid = ({ instaPosts, instaClicked }) => {
    // Memoize the rendered grid of Instagram posts
    const memoizedInstaPosts = useMemo(() => {
        return instaPosts.map((item, index) => (
            <div key={index} className="itemContainer" onClick={() => instaClicked(item.permalink)}>
                <img loading='lazy' src={item.mediaUrl} className="itemStyle" alt={`Post ${index + 1}`} />
            </div>
        ));
    }, [instaPosts, instaClicked]); // Dependencies for useMemo

    return <div className="gridStyle">{memoizedInstaPosts}</div>;
};

export { InstaGrid, InstaGridSkeleton };
