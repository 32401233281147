import React from 'react';
import Skeleton from 'react-loading-skeleton'; // For loading skeletons
import 'react-loading-skeleton/dist/skeleton.css';

const BlogPostCardSkeleton = () => {
    return (
        <div style={{ marginTop: 20 }}>
            <div className="blogimagecard">
                <Skeleton width="100%" height={200} style={{ borderRadius: 10 }} />
            </div>
            <Skeleton width={100} style={{ marginTop: 10 }} />
            <Skeleton width="80%" height={30} style={{ marginTop: 10 }} />
            <Skeleton count={3} />
        </div>
    );
};


const BlogPostCard = React.memo(({ blog, index, blogClicked, toggleExpand, expanded }) => {
    return (
        <div style={{ marginTop: 20 }}>
            <div
                onClick={() => blogClicked(blog)}
                className="blogimagecard"
            >
                <img
                    className="blogimage"
                    loading='lazy'
                    alt={`${blog.title}_image`}
                    src={blog.imgSrc ? blog.imgSrc : `${process.env.PUBLIC_URL + "/placeholder.webp"}`}
                    style={{ width: "100%", borderRadius: 10, objectFit: 'cover' }}
                />
            </div>

            <span className="cattext">{blog.category}</span>
            <h6 style={{ marginTop: 10 }}>{blog.title}</h6>
            <div className="expandable-text">
                <p className={expanded ? 'expanded' : 'collapsed'} dangerouslySetInnerHTML={{ __html: blog ? blog.pContent : '' }}></p>
                {blog && blog.pContent !== '' && (
                    <button className="morebtn" onClick={(e) => {
                        e.stopPropagation(); // Prevent the click from triggering blogClicked
                        toggleExpand(index);
                    }} style={{ color: 'orange', textDecoration: 'underline' }}>
                        {expanded ? 'Weniger sehen' : 'Mehr ansehen'}
                    </button>
                )}
            </div>
        </div>
    );
});

export { BlogPostCard, BlogPostCardSkeleton };
