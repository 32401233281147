import React, { useState, useContext } from 'react';
import '../Login/LoginForm.css'
import { useNavigate } from 'react-router-dom';
import { Strings, URLS } from '../../utilities/constants';
import { DataContext } from '../../utilities/DataContext';
import axios from 'axios'
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import '../../app.css'

const LoginForm = ({ onClose, onForgotBtnClick }) => {

    const navigate = useNavigate();
    const { setUserData, setToken, setIsLoggedIn, setUserType, setCompanyInfo } = useContext(DataContext)
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const validateForm = () => {
        if (!email.trim()) {
            setError(Strings.email_err);
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError(Strings.invalid_email);
            return false;
        }
        if (!password.trim()) {
            setError(Strings.password_err);
            return false;
        }
        return true;
    };

    const handleLogin = () => {
        if (validateForm()) {
            callLoginapi()
        }
    };

    const callLoginapi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL1}${URLS.LOGIN_URL}`;
        console.log('url', url)
        const formdata =
        {
            "email": email,
            "password": password
        }
        console.log('inputdata', formdata)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.post(url, formdata)
            .then((response) => {
                setLoading(false)
                console.log('response', response)
                if (response?.data.status === 1) {
                    setUserType(response?.data.user.user_type)
                    if (response?.data.user.user_type === 'private-user') {
                        const data = response?.data.user
                        setUserData(data);
                        setCompanyInfo(response?.data.private_user_profile_information)
                    } else {
                        const data = response?.data.user
                        setUserData(data);
                        setCompanyInfo(response?.data.company_information)
                    }
                    setToken(response?.data?.token)
                    setUserType(response?.data.user.user_type)
                    setIsLoggedIn(true)
                    setEmail('');

                    setPassword('')
                    if (response?.data.is_companyinfo_completed === false) {
                        // navigate('/businessform', { state: { isfrom: 'registration' } })
                        navigate('/businessform')
                    } else {
                        onClose()
                    }
                } else {
                    console.log('response', response);
                    // alert(response.data.message)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                setError(error ? error.response.data.message : '')

            });
    }

    return (
        <div className="modal-overlay">
            {loading && (
                <div className="loader">
                    <ClipLoader color='orange' loading={loading} size={50} />
                </div>
            )}
            <div className="modalstyle">
                <div style={{ height: '5vh' }}></div>

                <input
                    type="email"
                    placeholder={Strings.email}
                    value={email}
                    className='logininput'
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder={Strings.password}
                    className='logininput'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <a style={{ color: 'orange', alignSelf: 'flex-end', display: 'flex' }} onClick={onForgotBtnClick}>{Strings.forgotPassword}</a>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                    <button className="login-btn" onClick={handleLogin}>{Strings.login}</button>
                    <button className="closebtn" onClick={onClose}  >{Strings.close}</button>
                </div>

            </div>
        </div>
    );
};

export default LoginForm;

