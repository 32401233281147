import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Strings, impressumLink, termsandconditions } from '../../utilities/constants';
import './Footer.css'
import '../../app.css'
const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer__container'>
                <div className='footer_buttons-wrapper'>
                    <div className='social-icon'>
                        <a href={'https://www.instagram.com/campervanspot_com/'} target="_blank" rel="noopener noreferrer">
                            <img style={{ width: 30, height: 30 }} className='socialimg' src={require('../../assets/instagram-white.png')} alt="Instagram" loading="" />
                        </a>
                    </div>
                    <div className='social-icon'>
                        <a href={'https://www.facebook.com/profile.php?id=61555277504722'} target="_blank" rel="noopener noreferrer">
                            <img style={{ width: 30, height: 30 }} className='socialimg' src={require('../../assets/facebook_white.png')} alt="Fb" loading="" />
                        </a>
                    </div>
                    <div className='social-icon'>
                        <a href={'https://www.linkedin.com/company/campervanspot-com-gmbh'} target="_blank" rel="noopener noreferrer">
                            <img style={{ width: 35, height: 35 }} className='socialimg' src={require('../../assets/linkedin-white.png')} alt="Linkedin" loading="" />
                        </a>
                    </div>

                </div>
                <Row className='rowfinisher'>
                    <Col md={1}></Col>
                    <Col className='footer_left_column' >
                        <h1 className='addressText'>{Strings.address}</h1>
                        <p className='a para'>campervanspot.com GmbH
                            <br></br>
                            Marienstr. 19
                            <br></br>
                            50259 Pulheim
                            <br></br>
                            Deutschland
                        </p>
                    </Col>
                    <Col className='footer_right_column'  >
                        <h5 className='addressText'>Rechtliches</h5>
                        <ul className='ul'>
                            <li>
                                <a className='a' target="_blank" href={impressumLink} rel="noopener noreferrer">Impressum</a>
                            </li>
                            <li>
                                <a className='a' target="_blank" href={termsandconditions} rel="noopener noreferrer">Datenschutzvereinbarung</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </footer>
    )
}
export default Footer;