import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from "./Home/Home";
// import SearchScreen from "./Search/Search";
// import FinisherScreen from "./Finisher/Finisher";
// import RegistrationScreen from "./Registration/Registration";
// import BusinessFormPage from "./BusinessForm/BusinessForm";
// import BusinessPage from "./BusinessPage/BusinessPage";
// import AdsMainPage from "./Ads/AdsMainPage";
// import AdPreviewPage from "./AdPreviewPage/AdPreviewPage";
// import AdsList from "./AdsList/AdsList";
// import ChangePassword from "./ChangePassword/ChangePassword";
// import SuccessPage from "./SuccessPage/SuccessPage";
// import ErrorPage from '../screens/ErrorPage/ErrorPage'
// import UserSelectionPage from "./UserPage/UserSelectionPage";
// import PrivateUserRegistration from "./PrivateUserRegistration/PrivateUserRegistration";
// import Profile from "./UserProfile/Profile";
// import Messaging from "./Messaging/Messaging";
// import RegistrationSuccess from "./PrivateUserRegistration/RegistrationSuccess";
import ScrollToTop from "../Components/ScrollToTop";

// const HomeScreen = lazy(() => import('./Home/Home'));
const SearchScreen = lazy(() => import('./Search/Search'));
const FinisherScreen = lazy(() => import('./Finisher/Finisher'));
const RegistrationScreen = lazy(() => import('./Registration/Registration'));
const BusinessFormPage = lazy(() => import('./BusinessForm/BusinessForm'));
const BusinessPage = lazy(() => import('./BusinessPage/BusinessPage'));
const AdsMainPage = lazy(() => import('./Ads/AdsMainPage'));
const AdPreviewPage = lazy(() => import('./AdPreviewPage/AdPreviewPage'));
const DashBoard = lazy(() => import('./DashBoard/DashBoard'));
const AdsList = lazy(() => import('./AdsList/AdsList'));
const ChangePassword = lazy(() => import('./ChangePassword/ChangePassword'));
const SuccessPage = lazy(() => import('./SuccessPage/SuccessPage'));
const ErrorPage = lazy(() => import('../screens/ErrorPage/ErrorPage'));
const UserSelectionPage = lazy(() => import('./UserPage/UserSelectionPage'));
const PrivateUserRegistration = lazy(() => import('./PrivateUserRegistration/PrivateUserRegistration'));
const Profile = lazy(() => import('./UserProfile/Profile'));
const Messaging = lazy(() => import('./Messaging/Messaging'));
const RegistrationSuccess = lazy(() => import('./PrivateUserRegistration/RegistrationSuccess'));
// const ScrollToTop = lazy(() => import('../Components/ScrollToTop'));


const MainNavigator = () => {
    useEffect(() => {
        console.log('xmlDoc')
    })
    return (
        <Router>
            <ScrollToTop />
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route index element={<HomeScreen />} />
                    <Route path="/home" element={<HomeScreen />} />
                    <Route path="/registrationtypeselection" element={<UserSelectionPage />} />
                    <Route path="/registration" element={<RegistrationScreen />} />
                    <Route path="/sichere-meine-unternehmensseite-auf-campervanspot" element={<RegistrationScreen />} />
                    <Route path="/private_user_registration" element={<PrivateUserRegistration />} />
                    <Route path="/businesspage/:companyName" element={<BusinessPage />} />
                    <Route path="/businessform" element={<BusinessFormPage />} />
                    <Route path="/search" element={<SearchScreen />} />
                    <Route path="/businesses" element={<FinisherScreen />} />
                    <Route path="/adcreate" element={<AdsMainPage />} />
                    <Route path="/ad/:adId" element={<AdPreviewPage />} />
                    <Route path="/dashboard" element={<DashBoard />} />
                    <Route path="/adslist" element={<AdsList />} />
                    <Route path="/changepassword" element={<ChangePassword />} />
                    <Route path="/successpage" element={<SuccessPage />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/chat" element={<Messaging />} />
                    <Route path="/registration_success" element={<RegistrationSuccess />} />
                    <Route path='*' element={<ErrorPage />} />
                    {/* Catch-all route */}
                </Routes>
            </Suspense>
        </Router >
    );
}

export default MainNavigator;