import React from 'react';
import CountryFlag from '../Search/CountryFlag'; // Import your CountryFlag component
import { formatCurrency, formatMilageNumber } from '../../utilities/constants'; // Adjust import based on your utility functions
import Skeleton from 'react-loading-skeleton'; // For loading skeletons
import 'react-loading-skeleton/dist/skeleton.css';

const AdCardSkeleton = () => {
    return (
        <div className="imagecard" style={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end', height: 250, marginBottom: 10 }}>
            <Skeleton style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 10 }} />
            <div className="adddata" style={{ width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 20, width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                    <Skeleton circle={true} width={30} height={30} />
                    <Skeleton width={150} style={{ marginLeft: 10 }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                        <div><img src={require('../../assets/seat.png')} style={{ height: 20, width: 20 }} alt='seat' /><Skeleton width={50} /></div>
                        <div><img src={require('../../assets/bed.png')} style={{ height: 15, width: 15 }} alt='bed' /><Skeleton width={50} /></div>
                    </div>
                    <div>
                        <Skeleton width={50} /><Skeleton width={50} style={{ marginTop: 5 }} />
                    </div>
                    <div>
                        <Skeleton width={80} />
                    </div>
                </div>
            </div>
        </div>
    );
};


const AdCard = React.memo(({ item, adClicked }) => {
    return (
        <div
            key={item.id}
            onClick={() => adClicked(item)}
            className='imagecard'
            style={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}
        >
            <img
                alt={`${item.vehicle_name}_image`}
                loading="lazy"
                src={item.hero_image_url_2k != null ? item.hero_image_url_2k : `${process.env.PUBLIC_URL + "/placeholder.webp"}`}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: 10,
                    objectFit: 'cover',
                }}
            />
            <div className='adddata' style={{ width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 20, width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                    <CountryFlag countryCode={item.country_code} />
                    <h1 style={{
                        fontSize: 17,
                        fontWeight: 400,
                        color: 'white',
                        marginBottom: 0,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                        {item.vehicle_name !== '' ? item.vehicle_name : '--'}
                    </h1>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <div>
                            <img src={require('../../assets/seat.png')} style={{ height: 15, width: 15 }} alt='seat' />
                            <span className='addetails'>{item.no_of_seats !== null && item.no_of_seats !== 0 ? item.no_of_seats : '--'}</span>
                        </div>
                        <div>
                            <img src={require('../../assets/bed.png')} style={{ height: 15, width: 15 }} alt='bed' />
                            <span className='addetails'>{item.total_no_of_beds !== null && item.total_no_of_beds !== 0 ? item.total_no_of_beds : '--'}</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <div md={3}>
                                <span className='addetails'>{item.year_of_manufacture !== null ? item.year_of_manufacture : '--'}</span>
                            </div>
                            <div md={9}>
                                <span className='addetails'>{item.mileage_km !== null && item.mileage_km !== 0 ? `${formatMilageNumber(item.mileage_km)} km` : '--'}</span>
                            </div>
                        </div>
                        <div>
                            <span className='addetails'>{(item.price !== null && item.currency !== null) ? formatCurrency(item.price, item.currency) : '--'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export { AdCard, AdCardSkeleton };
