import React from "react";
import { createRoot } from "react-dom/client";
import App from './App';
import { DataProvider } from "./utilities/DataContext";
import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';



const rootElement = document.getElementById("root");

// Replace ReactDOM.render with createRoot
const root = createRoot(rootElement);
root.render(<DataProvider><App /></DataProvider>);
