import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../utilities/DataContext';

const CountryFlag = ({ countryCode }) => {
    const [flagUrl, setFlagUrl] = useState('');

    const { flagsData } = useContext(DataContext)

    useEffect(() => {
        const fetchFlag = async () => {
            try {
                const country = flagsData.find((data) => data.code.toUpperCase() === countryCode.toUpperCase());

                const url = country.flagUrl;
                console.log(`Fetched flag URL:`, url);
                setFlagUrl(url);
            } catch (error) {
                console.error('Error fetching the country flag:', error);
            }
        };
        fetchFlag();
    }, [countryCode]);

    return (
        <div style={{ alignContent: 'center', display: 'flex', width: 25, height: 25, marginRight: 10, }}>
            {flagUrl && (
                <img src={flagUrl} alt={`${countryCode} flag`} style={{ borderRadius: 2, width: '100%', height: '100%' }} />
            )}
        </div>
    );
};

export default CountryFlag;
