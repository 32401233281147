// src/analytics.js

// Set the page title and send page view
export const trackPageView = (title) => {
    if (window.gtag) {
        window.gtag('event', 'page_view', {
            page_path: window.location.pathname + window.location.search,
            page_title: title
        });
    }
};

