import React, { useEffect, useContext, useState, useMemo, useCallback } from "react";
import "../Home/styles.css";
import "../../app.css";
import Header from "../../../src/Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Strings, URLS, instaAccessToken, instaUserId } from "../../utilities/constants";
import { DataContext } from "../../utilities/DataContext";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import usePageView from "../../hooks/usePageView";
import { CompanyCard, CompanyCardSkeleton } from './CompanyItem';
import { AdCard, AdCardSkeleton } from "./AdItem";
import { BlogPostCard, BlogPostCardSkeleton } from './BlogItem'
import { InstaGrid, InstaGridSkeleton } from "./InstaItem";
import AlertComponent from "../../Components/AlertComponent";

const portalId = "143650051";
const formId = "c660dded-f3c4-45d2-b813-15bb0fff03d6";



const HomeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formReady, setFormReady] = useState(false)
  const [addsList, setAddsList] = useState([])
  const [companiesData, setCompaniesData] = useState([]);
  const [blogsData, setBlogsData] = useState([])
  const [expandedStates, setExpandedStates] = useState(new Array(blogsData.length).fill(false));
  const { setBusinessForm1data, setBusinessForm2data, token, setIsLoggedIn, setToken, setUserData, setCompanyInfo, setUserType, userData, setChatData, setMessageCount, setTab, setNoOfBedErr, setAddDetails } = useContext(DataContext);
  const [instaPosts, setInstaPosts] = useState([])
  const [color, setColor] = useState('green')
  const [message, setMessage] = useState('');
  const [companyLoading, setCompanyLoading] = useState(false);
  const [adsLoading, setAdsLoading] = useState(false);
  const [blogsLoading, setBlogsLoading] = useState(false);
  const [instaLoading, setInstaLoading] = useState(false);
  const [responseErr, setResponseErr] = useState('')

  // usePageView('campervanspot.com - Der Marktplatz für hochwertige Camper Vans und Wohnmobile', true)

  useEffect(() => {
    // document.title = 'campervanspot.com - Der Marktplatz für hochwertige Camper Vans und Wohnmobile'
    // HubSpot form integration (ensure the form loads after media fetch)
    if (window.hbspt && window.hbspt.forms) {
      createHubSpotForm();
    } else {
      // Load the HubSpot script if not already loaded
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      script.charset = "utf-8";
      script.async = true;
      script.onload = createHubSpotForm;
      document.body.appendChild(script);
    }
  }, []);


  useEffect(() => {
    return () => {
      // Optionally, remove the script if necessary
      const existingScript = document.querySelector(`script[src="https://js.hsforms.net/forms/v2.js"]`);
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [])


  const gotoLogin = () => {
    setResponseErr('')
    setUserData({})
    setCompanyInfo({})
    setUserType('')
    setTab('')
    setNoOfBedErr('')
    setMessageCount('')
    setToken('')
    setChatData([])
    setBusinessForm1data({})
    setBusinessForm2data({})
    setIsLoggedIn(false)
    setAddDetails({})
  }

  const apicalls = async () => {
    const searchParams = new URLSearchParams(location.search);

    const token = searchParams.get('token');
    const uuid = searchParams.get('uuid');
    const usertype = searchParams.get('user_type');

    console.log('token', token);
    console.log('uuid', uuid);
    if (uuid != null && token != null && usertype === 'private-user') {
      setUserType(usertype)
      getverifyStatus(uuid, token)
    }

    if (userData && userData.id) {
      getChatList()
    }
    callAddsListApi()
  }

  const createHubSpotForm = async () => {
    if (window.hbspt) {
      setFormReady(true)
      await window.hbspt.forms.create({
        portalId: portalId,
        formId: formId,
        target: "#hubspotFormContainer",

        onFormReady: function () {
          console.log("Form ready!");
          // This function will be called when the form is ready
          myFunction();
        },
        onFormSubmit: function ($form) {
          // This function will be called when the form is submitted
          console.log("Form submitted!");
          // Now, you can wait for the success message iframe to appear and then access its content
          setTimeout(getSuccessMessageIframeContent, 1000); // Adjust the timeout as needed
        }
      });
    }
  };

  const getSuccessMessageIframeContent = () => {
    const successMessageIframe = document.querySelector("iframe.hs-form-iframe");

    if (successMessageIframe) {
      const successMessageIframeDoc = successMessageIframe.contentWindow.document;
      console.log('successMessageIframeDoc', successMessageIframeDoc)
      const pTagElement = successMessageIframeDoc.querySelector('p');
      console.log(pTagElement.textContent);
      if (pTagElement) {
        pTagElement.textContent = 'Vielen Dank für die Einsendung, wir halten Sie jetzt auf dem Laufenden über unsere weitere Entwicklung.';
        pTagElement.style.color = '#52c41a';
        pTagElement.style.fontWeight = 400;
        pTagElement.style.fontSize = 20
      }
    } else {
      console.error("Success message iframe not found");
    }
  };



  const getverifyStatus = async (uuid, token) => {
    const url = `${URLS.BASE_URL}${URLS.VERIFY_EMAIL}${uuid}`;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        if (response?.data.status === 1) {
          console.log("response", response);
          // if (response?.data.message === 'Benutzerkonto bereits verifiziert') {
          setMessage(response?.data.message)
          const token = response?.data.token
          const userData = response?.data.user
          const compnayinfo = response?.data.private_user_profile_information
          setUserData(userData);
          setCompanyInfo(compnayinfo)
          setIsLoggedIn(true)
          setToken(token)
          if (response?.data.message === 'Benutzerkonto bereits verifiziert') {
            setMessage('')

          } else {
            setColor('green')
          }
        } else {
          setIsLoggedIn(false)
          setMessage(response?.data.message)
        }
      })
  }



  const myFunction = () => {

    var iframe = document.querySelector("iframe.hs-form-iframe");

    if (iframe) {
      console.log('iframe', iframe)
      var iframeDoc = iframe.contentWindow.document;
      // const maindiv = iframeDoc.querySelector(".hs-form-private");
      var actionsDiv = iframeDoc.querySelector(".actions");
      if (actionsDiv) {
        actionsDiv.style.justifyContent = "flex-end";
        actionsDiv.style.display = "flex";
        actionsDiv.style.padding = 0;
      } else {
        console.error("Element with class 'actions' not found in the iframe");
      }

      const inputfirstname = iframeDoc.getElementById(
        "firstname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      const inputlastname = iframeDoc.getElementById(
        "lastname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      const inputemail = iframeDoc.getElementById(
        "email-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      const labelfirstname = iframeDoc.getElementById(
        "label-firstname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );
      const labellastname = iframeDoc.getElementById(
        "label-lastname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );
      const labelemail = iframeDoc.getElementById(
        "label-email-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );
      var input = iframeDoc.querySelector("input.hs-button.primary.large");

      if (input) {
        input.style.backgroundColor = "transparent"; // Example style change
        input.style.borderColor = "white";
        // Add more style changes here as needed
      }

      if (labelfirstname) {
        labelfirstname.style.display = "none";
      }

      if (inputfirstname) {
        inputfirstname.style.backgroundColor = "transparent";
        inputfirstname.style.color = "white";
        inputfirstname.style.borderColor = "white";
      }
      if (labellastname) {
        labellastname.style.display = "none";
      }
      if (inputlastname) {
        inputlastname.style.backgroundColor = "transparent";
        inputlastname.style.color = "white";
        inputlastname.style.borderColor = "white";
      }
      if (labelemail) {
        labelemail.style.display = "none";
      }
      if (inputemail) {
        inputemail.style.backgroundColor = "transparent";
        inputemail.style.color = "white";
        inputemail.style.borderColor = "white";
      }

    } else {
      console.error("Iframe element with ID 'myFrame' not found");
    }
    apicalls()
  };

  const toggleExpand = useCallback(
    (index) => {
      const newExpandedStates = [...expandedStates];
      newExpandedStates[index] = !newExpandedStates[index];
      setExpandedStates(newExpandedStates);
    },
    [expandedStates] // Only re-create when expandedStates changes
  );

  const nextClicked = useCallback(
    (company) => {
      setBusinessForm1data({});
      setBusinessForm2data({});
      const formattedCompanyName = formatCompanyName(company.company_name);
      navigate(`/businesspage/${formattedCompanyName}`, { state: { id: company.id } });
    },
    [navigate, setBusinessForm1data, setBusinessForm2data] // Add any other necessary dependencies, such as formatCompanyName if it depends on outside variables
  );


  const blogClicked = useCallback(
    (blog) => {
      window.open(blog.link, '_blank', 'noopener,noreferrer');
    },
    [] // No dependencies needed here since the function doesn't rely on external values
  );

  const instaClicked = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  }
  const formatCompanyName = (name) => {
    return name
      .toLowerCase()                          // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
  };



  const moreClicked = (company) => {
    navigate("/businesses");
  };

  const moreAdsClicked = () => {
    navigate("/search");
  };

  const searchClicked = (company) => {
    navigate("/search");
  };

  const getChatList = async () => {
    const url = `${URLS.BASE_URL}${URLS.CHAT_LIST}`;
    await axios.get(url, { headers: { Authorization: token } })
      .then((response) => {
        if (response?.data.status === 1) {
          if (response?.data.data.length > 0) {
            setChatData(response?.data.data);
          }
        } else {
          console.log('response', response);
        }
      })
      .catch(function (error) {
        console.log('error', error.response.data.message);
        if (error.response) {
          // Handle error response
          if (error.response.data.message === `Ung\u00fcltiges Token. Bitte melden Sie sich erneut an.`) {
            setResponseErr(error.response.data.message)
          }
        }
      });
  };


  const getCompanies = async () => {
    setCompanyLoading(true)
    const url = `${URLS.BASE_URL}${URLS.ALL_COMPANIES}?order=descending`;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    await axios
      .get(url)
      .then((response) => {
        setCompanyLoading(false)
        fetchBlogsData()
        if (response?.data.status === 1) {
          const data = response?.data.data;
          const filteredCompanies = data.filter(company => company.companybusinesspage !== null);
          setCompaniesData(filteredCompanies);
        } else {
          alert("", response.data.message);
        }
      })
      .catch(function (error) {
        setCompanyLoading(false)
        fetchBlogsData()
        console.log("error", error);
      });
  };

  const fetchBlogsData = async () => {
    setBlogsLoading(true)
    await fetch('https://app2.campervanspot.com/blog/rss.xml')
      .then(response => response.text())
      .then(data => {
        setBlogsLoading(false)
        fetchInstMedia()
        // Parse the XML data
        const parser = new DOMParser();
        const xml = parser.parseFromString(data, 'text/xml');
        const itemNodes = xml.getElementsByTagName('item');

        // Convert XML nodes to JavaScript objects
        const itemsArray = Array.from(itemNodes).map(item => {
          const descriptionNode = new DOMParser().parseFromString(item.getElementsByTagName('description')[0].textContent, 'text/html');
          const imgSrc = descriptionNode.querySelector('img')?.src;
          const pContent = descriptionNode.querySelector('p')?.textContent;
          const linkHref = descriptionNode.querySelector('a')?.href;

          return {
            title: item.getElementsByTagName('title')[0].textContent,
            link: item.getElementsByTagName('link')[0].textContent,
            pubDate: item.getElementsByTagName('pubDate')[0].textContent,
            category: item.getElementsByTagName('category')[0].textContent,
            author: item.getElementsByTagName('author')[0].textContent,
            imgSrc,
            pContent,
            linkHref,
          };
        });
        console.log('setBlogsData', itemsArray)
        setBlogsData(itemsArray);
      }).catch(error => {
        setBlogsLoading(false)
        fetchInstMedia()
        console.error('Error fetching or parsing XML:', error)
      });

  }

  const getUserId = async () => {

  }
  const fetchInstMedia = async () => {
    try {
      setInstaLoading(true); // Set loading state to true

      // Check if the user ID or access token is undefined
      if (!instaUserId || !instaAccessToken) {
        console.error('Instagram userId or accessToken is undefined:', { instaUserId, instaAccessToken });
        setInstaLoading(false);
        return;
      }

      const instaurl = `https://graph.instagram.com/${instaUserId}/media?access_token=${instaAccessToken}`;

      // Fetch the Instagram media
      const response = await fetch(instaurl);

      // Check if the response is ok (status code 200-299)
      if (!response.ok) {
        throw new Error(`Failed to fetch Instagram media: ${response.status} - ${response.statusText}`);
      }

      const json = await response.json();
      const mediaData = json.data;

      if (!mediaData || mediaData.length === 0) {
        console.warn('No media found for the Instagram user.');
        setInstaPosts([]); // Set an empty list if no media is found
        setInstaLoading(false);
        return;
      }

      const fetchedItems = [];

      // Loop through the media items and fetch details for each
      for (let i = 0; i < mediaData.length && i < 9; i++) {
        const item = mediaData[i];
        const itemId = item.id;

        // Fetch Instagram image details
        const instaItem = await fetchInstaimages(itemId);

        // Collect only non-video items (and limit to 6)
        if (fetchedItems.length < 6 && instaItem.mediaType !== "VIDEO") {
          fetchedItems.push(instaItem);
        }
      }

      // Update the state with the fetched Instagram posts
      setInstaPosts(fetchedItems);
      console.log('Fetched Instagram items:', fetchedItems);
    } catch (error) {
      console.error('Error fetching Instagram media:', error);
      setInstaPosts([]); // Set an empty list in case of failure
    } finally {
      setInstaLoading(false); // Always turn off loading state
    }
  };


  const fetchInstaimages = async (id) => {
    const mediaurl = `https://graph.instagram.com/${id}?access_token=${instaAccessToken}&fields=media_url,permalink,media_type`
    const response = await fetch(mediaurl)
    const json = (await response.json())
    const item = {
      'permalink': json.permalink,
      'mediaUrl': json.media_url,
      'mediaType': json.media_type
    }
    return item
  }

  const adClicked = useCallback(
    (item) => {
      const componentPage = `/ad/${item.id}`;
      navigate(componentPage);
    },
    [navigate] // Include 'navigate' if it is coming from props or context
  );

  const buildUrlWithParams = (baseUrl, params) => {
    const query = Object.keys(params)
      .map(key => {
        const value = params[key];
        if (Array.isArray(value)) {
          return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');
    return `${baseUrl}?${query}`;
  };

  const callAddsListApi = async () => {
    setAdsLoading(true)

    const url = buildUrlWithParams(`${URLS.BASE_URL}${URLS.SEARCH_ADDS}`, {
      search: '',
      chasis_brand: '',
      conversion_brand: '',
      min_mileage: '',
      max_mileage: '',
      min_price: '',
      max_price: '',
      type: '',
      total_no_of_beds: '',
      page: 1,
      page_size: 3
    });

    // const url = `${URLS.BASE_URL}${URLS.SEARCH_ADDS}search=${formData.vehiclename}&chasis_brand=${formData.chasisbrand.join(',')}&conversion_brand=${formData.conversionbrnd.join(',')}&min_mileage=${formData.milagerangefrom}&max_mileage=${formData.milagerangeto}&min_price=${formData.pricerangefrom}&max_price=${formData.pricerangeto}&type=${formData.type.join(',')}`;
    console.log('adurl', url)
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setAdsLoading(false)
        getCompanies();
        if (response?.data.status === 1) {
          const data = response.data.data
          if (data.length > 3) {
            const slicedArray = data.slice(0, 3)
            setAddsList(slicedArray)
          } else {
            setAddsList(data)
          }

        } else {
          console.log('response', response);
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        setAdsLoading(false)
        getCompanies();
        console.log('error', error);
        alert(error.response.data.message);
      });
  }

  const memoizedAds = useMemo(
    () =>
      addsList.map((item) => (
        <AdCard
          key={item.id}
          item={item}
          adClicked={adClicked}
        />
      )),
    [addsList, adClicked]
  );


  const memoizedCompanies = useMemo(
    () =>
      companiesData.map((company) => (
        <CompanyCard
          key={company.id}
          company={company}
          nextClicked={nextClicked}
        />
      )),
    [companiesData, nextClicked]
  );

  const memoizedBlogs = useMemo(
    () =>
      blogsData.map((blog, index) => (
        <BlogPostCard
          key={index} // It’s generally better to use a unique identifier if available, like blog.id
          blog={blog}
          index={index}
          blogClicked={blogClicked}
          toggleExpand={toggleExpand}
          expanded={expandedStates[index]}
        />
      )),
    [blogsData, blogClicked, toggleExpand, expandedStates]
  );




  return (
    <>
      <Header />
      <div style={{ height: "100vh" }}>
        {(message !== '') ?
          <div style={{ backgroundColor: color === 'green' ? 'lightgreen' : 'orange', paddingLeft: 20, paddingRight: 20, width: '100%', display: 'flex', justifyContent: 'space-between', zIndex: 5000, position: 'absolute', marginTop: 0, flexDirection: 'row' }}>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ height: 25, width: 25, marginRight: 10, marginLeft: 10 }} src={color === 'green' ? require('../../assets/green_check.png') : require('../../assets/red_check.png')}></img>
              <span style={{ color: color === 'green' ? 'green' : 'red' }}>{message}</span>
            </div>
            <span className="close-button-home" onClick={() => { setMessage('') }}>&times;</span>
          </div> : null
        }
        <div
          className='home-banner  home-dnd_area-module'
        >

          <picture>
            <img className="bg_image" src="/natureimage_small.webp" alt="HeroImage" />
          </picture>
          <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.15)' }}></div>
          <div className="container">
            <div className='banner-content-wrapper-home'>
              <div>
                <div className="normaltextrow">
                  <div className="view_line" />
                  <div className="normaltextStyle">Camper & Vans</div>
                </div>
                <div>
                  {/* <Col md={12} sm={12} lg={12} xl={8} xs={12}> */}
                  <h6 className="mediumtextstyle">
                    Von Abenteurern für Abenteurer
                  </h6>
                  <h2 className="boldTextStyle">Der Marktplatz für hochwertige Campervans und Wohnmobile</h2>
                  {/* </Col> */}
                </div>

                <div className={`formdiv ${formReady ? 'fade-in' : ''}`}>
                  <span className="hubspotheader">
                    Bleiben Sie auf dem Laufenden!
                  </span>

                  <div
                    id="hubspotFormContainer"
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      minHeight: 250,
                      paddingBottom: 20
                    }}
                  ></div>
                </div>


                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', position: 'absolute', paddingLeft: '4vw', paddingRight: '4vw' }}>
                  <div className="div-search">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <button className="search-text1" style={{ backgroundColor: 'white', padding: '8px 40px 8px 40px', color: 'black' }}>Suche</button>
                      <button className="search-text1" style={{ backgroundColor: 'rgba(0,0,0,0.5)', padding: '8px 40px 8px 40px', color: 'white' }}>Filter</button>
                    </div>

                    <div className="shadow-div">
                      {/* <span className="search-text">Wir stehen kurz davor die Fahrzeugsuche zu starten!</span> */}
                      <div style={{ borderRadius: 5, width: '100%', height: 50, paddingLeft: '5vmin', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => {
                        searchClicked()
                      }}>
                        <input disabled={true} placeholder='Was suchen Sie?' className='home_search_input' style={{ color: 'black', fontSize: 20, flex: 1, pointerEvents: 'none' }} onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }} />
                        <div style={{ padding: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, paddingRight: '5vmin' }}>
                          <img style={{ height: 25, width: 25, }} alt="search" src={require('../../assets/search_black.png')}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", paddingTop: "8vh", flexDirection: "column" }}>
          <div className='cardcontainer'>
            <div className="heading">
              <h3 className="newbusiness_headingtext">
                {"Neueste"} <strong>{"Fahrzeug Anzeigen"}</strong>{" "}
              </h3>
              <div key={'adsDevider'} className="divider" />
            </div>
            <div className='section_cards'>
              {adsLoading
                ? Array(3).fill(<AdCardSkeleton />) // Show 6 skeletons while loading
                : memoizedAds
              }
            </div>
            {/* </div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 0,
            }}
          >
            <button className="btnmore" onClick={moreAdsClicked}>
              {Strings.all_btn}
            </button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", }}>
          <div className='cardcontainer'>
            <div className="heading">
              <h3 className="newbusiness_headingtext">
                {"Neueste"} <strong>{"Camper Van Ausbauer"}</strong>{" "}
              </h3>
              <div key={'companyDevider'} className="divider" />
            </div>
            <div className="section_cards">
              {companyLoading
                ? Array(3).fill(<CompanyCardSkeleton />) // Show 6 skeletons while loading
                : memoizedCompanies
              }

            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",

            }}
          >
            <button className="btnmore" onClick={moreClicked}>
              {Strings.all_btn}
            </button>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className='cardcontainer'>
            <div className="heading">
              <h3 className="newbusiness_headingtext">
                {"Aktuelle"} <strong>{"Magazin Beiträge"}</strong>{" "}
              </h3>
              <div key={'blogDevider'} className="divider" />
            </div>
            <div className="section_cards" >
              {blogsLoading
                ? Array(3).fill(<BlogPostCardSkeleton />) // Show 6 skeletons while loading
                : memoizedBlogs
              }
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", paddingBottom: 30 }}>
          <div className='cardcontainer'>
            <div className="heading">
              <h3 className="newbusiness_headingtext">
                {"Top"} <strong>{"Instagram Posts"}</strong>{" "}
              </h3>
              <div key={'instapostDevider'} className="divider" />
            </div>
            {instaLoading
              ? <InstaGridSkeleton /> // Show 6 skeletons while loading
              : <InstaGrid instaPosts={instaPosts} instaClicked={instaClicked} />
            }
          </div>
        </div>
        <Footer />
        {responseErr !== '' && <AlertComponent message={responseErr} onClose={() => { gotoLogin() }} />}
      </div >

    </>
  );
};

export default HomeScreen;