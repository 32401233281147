import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from './analytics';
import { getCookieValue } from '../utilities/constants';


const usePageView = (title) => {
    const location = useLocation();



    useEffect(() => {
        // Track the current page view with the full URL
        const consentCookie = getCookieValue('cookie_consent_level');
        if (consentCookie) {
            const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
            console.log('consentStatus', consentStatus.tracking);
            if (consentStatus.tracking) {
                if (title) {
                    document.title = title;
                }
                trackPageView(title || document.title);
            }

        }

    }, [location, title]);

    // useEffect(() => {
    //     if (pageTitle !== '') {
    //         document.title = pageTitle;
    //     }

    //     // const consentCookie = getCookieValue('cookie_consent_level');
    //     // if (consentCookie) {
    //     //     const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
    //     //     console.log('consentStatus', consentStatus.tracking);
    //     //     if (isTitleReady && window.gtag) {
    //     //         window.gtag('config', 'G-4CG75M28S5', {
    //     //             page_title: pageTitle,
    //     //             page_path: location.pathname,
    //     //         });
    //     //     }    
    //     // }

    //     if (isTitleReady && pageTitle !== '' && window.gtag) {
    //         // window.gtag('config', 'G-4CG75M28S5', {
    //         //     page_title: pageTitle,
    //         //     page_path: location.pathname,
    //         // });
    //     } else if (pageTitle === '' && window.gtag) {
    //         // When the title is not ready, configure gtag to not send the page view automatically
    //         // window.gtag('config', 'G-4CG75M28S5', {
    //         //     page_path: location.pathname,
    //         //     send_page_view: false,
    //         // });
    //     }
    // }, [pageTitle, isTitleReady, location.pathname]);
};

export default usePageView;